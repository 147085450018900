<template>
  <div class="appeal-find">
    <div class="mobile-test creat-dialog">
      <el-dialog
        v-model="mobileTestVisible"
        title="验证手机"
        :close-on-click-modal="false"
        :before-close="handleClose"
        width="35%"
      >
        <div class="dialog-content">
          <div class="test-tip">
            <svg-icon icon-class="warning" class="svg" />
            验证码已发送到您的手机，10分钟内输入有效，请勿泄露。
          </div>
          <el-form
            ref="applyBillForm"
            :model="mobileForm"
            :rules="mobileFormRules"
            label-width="180px"
            label-position="right"
          >
            <el-form-item label="手机号码" style="margin-bottom: 20px;">
              {{ appealInfos.mobile }}
            </el-form-item>
            <div v-if="isShowCode" style="display: flex;margin-bottom: 20px;">
              <el-form-item style="margin-bottom: 0;" label="图片验证码" prop="code">
                <el-input v-model="mobileForm.code" placeholder="请输入图片验证码" style="width: 150px;margin-right: 12px;" />
              </el-form-item>
              <div style="width: 138px;" @click="getCode">
                <img v-if="image" class="image" :src="image" style="cursor: pointer;" alt="点击刷新">
                <el-button v-else type="text">点击刷新</el-button>
              </div>
            </div>
            <el-form-item label="手机验证码" prop="mobileCode" style="margin-bottom: 20px;">
              <div class="phone-code-box">
                <el-input v-model="mobileForm.mobileCode" placeholder="请输入手机验证码" />
                <el-button class="send" :disabled="num !== ''" @click="send">
                  重发{{ num ? '(' + num + ')' : '' }}
                </el-button>
              </div>
              <div style="color: #2a9c3d;font-size: 12px;line-height: 1;margin-top: 6px;">
                请输入您的手机收到的验证码
              </div>
            </el-form-item>
          </el-form>
          <div style="text-align: center;">
            <el-button :disabled="appealDisabled" style="width: 80px;" type="primary" @click="submitAppealInfos">提交</el-button>
          </div>
        </div>
        <!--        <div slot="footer">-->
        <!--          &lt;!&ndash;          <el-button size="small" @click="workNameVisible = false">取消</el-button>&ndash;&gt;-->
        <!--          <el-button size="small" type="primary" @click="submit">提交</el-button>-->
        <!--        </div>-->
      </el-dialog>
    </div>
    <div class="content">
      <div style="width: 900px;display: flex;flex-direction: column;align-items: center;">
        <div class="steps">
          <el-steps :active="activeStep" align-center finish-status="finish" process-status="process">
            <el-step title="基础信息" />
            <el-step title="补充材料" />
            <el-step title="等待客服审核" />
          </el-steps>
        </div>
        <div v-if="activeStep === 0" class="step-0 step-content">
          <el-form ref="appealInfos" :model="appealInfos" :rules="appealRules" label-width="120px">
            <el-form-item label="选择申诉原因" prop="reason">
              <el-select v-model="appealInfos.reason" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="username">
              <el-input v-model="appealInfos.username" />
            </el-form-item>
            <el-form-item label="身份证号码" prop="idCard">
              <el-input v-model="appealInfos.idCard" />
            </el-form-item>
            <el-form-item label="联系邮箱" prop="email">
              <el-input v-model="appealInfos.email" />
            </el-form-item>
            <el-form-item label="联系手机" prop="mobile">
              <el-input v-model="appealInfos.mobile" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="width: 100px;" @click="next">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="activeStep === 1" class="step-1 step-content">
          <el-form ref="supplyInfos" :model="supplyInfos" :rules="supplyRules" label-width="120px">
            <el-form-item label="注册时间" prop="registerTime">
              <el-date-picker
                v-model="supplyInfos.registerTime"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
              />
            </el-form-item>
            <el-form-item label="曾用手机号" prop="pastMobile">
              <el-input v-model="supplyInfos.pastMobile" placeholder="请输入曾用手机号" @blur="pastMobileBlur" />
            </el-form-item>
            <el-form-item label="累计充值金额" prop="credit">
              <el-input v-model="supplyInfos.credit" placeholder="请输入累计充值金额" @blur="creditBlur" />
            </el-form-item>
            <el-form-item label="其他描述" prop="description">
              <el-input v-model="supplyInfos.description" type="textarea" maxlength="100" show-word-limit :autosize="{ minRows: 6}" placeholder="请输入对这个账号的描述" />
            </el-form-item>
            <div style="display: flex;margin-bottom: 22px;">
              <el-form-item style="margin-bottom: 0;" label="验证码" prop="code">
                <el-input v-model="supplyInfos.code" placeholder="请输入验证码" style="width: 150px;margin-right: 12px;" />
              </el-form-item>
              <div style="width: 138px;" @click="getCode">
                <img v-if="image" class="image" :src="image" style="cursor: pointer;" alt="点击刷新">
                <el-button v-else type="text">点击刷新</el-button>
              </div>
            </div>
            <el-form-item>
              <el-button :disabled="submitDisabled" type="primary" style="width: 100px;margin-top: 18px;" @click="getSmsCode">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="activeStep === 2" class="step-2 step-content">
          <svg-icon icon-class="success" class-name="svg" />
          资料提交成功，因人工审核较慢，请耐心等待8-24小时，注意接听来电。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validate from '@/utils/baseValidate.js'
import { ElMessage } from 'element-plus'
import { getFindPwdSms } from '@/api/sms'
import { captcha, appealFindPassword } from '@/api/user'
export default {
  name: 'AppealFind',
  data() {
    const checkMobile = (rule, value, callback) => {
      const reg = new RegExp('^1[3-9]\\d{9}$')
      if (value.trim().length !== 0 && !reg.test(value.trim())) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      appealDisabled: false,
      submitDisabled: false,
      isShowCode: false,
      num: '',
      mobileForm: {
        mobileCode: '',
        code: ''
      },
      mobileFormRules: {
        mobileCode: [
          { required: true, message: '请输入手机验证码', trigger: ['blur', 'change'] }
        ]
      },
      mobileTestVisible: false,
      keys: '',
      image: '',
      supplyInfos: {
        registerTime: '',
        pastMobile: '',
        credit: '',
        description: '',
        code: ''
      },
      supplyRules: {
        registerTime: [
          // { required: true, message: '请输入注册时间', trigger: 'change' }
        ],
        pastMobile: [
          { validator: checkMobile, trigger: 'blur' }
        ],
        credit: [
          { validator: validate.priceV2, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入输入验证码', trigger: 'blur' }
        ]
      },
      options: [
        { label: '手机停用', value: '手机停用' },
        { label: '管理人员离职', value: '管理人员离职' },
        { label: '其他', value: '其他' }
      ],
      activeStep: 0,
      appealInfos: {
        reason: '',
        username: '',
        idCard: '',
        email: '',
        mobile: ''
      },
      appealRules: {
        reason: [
          { required: true, message: '请选择申诉原因', trigger: 'change' }
        ],
        username: [
          { required: true, validator: validate.linkName, trigger: 'blur' }
        ],
        idCard: [
          { required: true, validator: validate.idCard, trigger: 'blur' }
        ],
        email: [{ required: true, trigger: 'blur', message: '请输入邮箱地址' }, { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        mobile: [
          { required: true, validator: validate.phone, trigger: 'blur' }
        ]
      },
      timer: null
    }
  },
  methods: {
    // 曾用手机号失去焦点验证
    pastMobileBlur() {
      this.$refs.supplyInfos.validateField('pastMobile')
    },
    // 金额失去焦点验证
    creditBlur() {
      this.$refs.supplyInfos.validateField('credit')
    },
    // 提交申诉信息
    submitAppealInfos() {
      this.appealDisabled = true
      const data = {
        reason: this.appealInfos.reason.trim(),
        username: this.appealInfos.username.trim(),
        idCard: this.appealInfos.idCard.trim(),
        email: this.appealInfos.email.trim(),
        mobile: this.appealInfos.mobile.trim(),
        registerTime: this.supplyInfos.registerTime,
        pastMobile: this.supplyInfos.pastMobile,
        credit: this.supplyInfos.credit,
        description: this.supplyInfos.description,
        smsCode: this.mobileForm.mobileCode.trim()
      }
      appealFindPassword(data, this.$route.query.account.trim()).then(res => {
        if (res.meta.status === 201) {
          this.mobileTestVisible = false
          this.appealDisabled = false
          this.activeStep++
        } else {
          ElMessage.error(res.meta.msg)
          this.appealDisabled = false
        }
      })
    },
    // 计时器
    timing() {
      this.num = 60
      this.timer = setInterval(() => {
        this.num--
        if (this.num === 0) {
          this.num = ''
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    handleClose() {
      this.mobileTestVisible = false
      this.num = ''
      this.$refs.applyBillForm.clearValidate()
      clearInterval(this.timer)
      this.timer = null
    },
    send() {
      this.isShowCode = true
      if (this.mobileForm.code === '') {
        ElMessage.error('请输入图片验证码！')
        return
      }
      const data = {
        account: this.$route.query.account.trim(),
        captchaKey: this.keys,
        code: this.mobileForm.code.trim(),
        phone: this.appealInfos.mobile.trim()
      }
      getFindPwdSms(data).then(res => {
        if (res.meta.status === 200) {
          this.timing()
          this.isShowCode = false
        } else {
          ElMessage.error(res.meta.msg)
        }
      })
    },
    // 获取手机验证码
    getSmsCode() {
      this.submitDisabled = true
      const data = {
        account: this.$route.query.account.trim(),
        captchaKey: this.keys,
        code: this.supplyInfos.code.trim(),
        phone: this.appealInfos.mobile.trim()
      }
      this.$refs.supplyInfos.validate(valid => {
        if (valid) {
          getFindPwdSms(data).then(res => {
            if (res.meta.status === 200) {
              this.mobileTestVisible = true
              this.submitDisabled = false
              this.timing()
              this.getCode()
            } else {
              this.submitDisabled = false
              ElMessage.error(res.meta.msg)
              this.getCode()
            }
          })
        } else {
          this.submitDisabled = false
        }
      })
    },
    // 获取验证码
    getCode() {
      captcha().then((res) => {
        this.image = res.data.image
        this.keys = res.data.key
      })
    },
    next() {
      this.$refs.appealInfos.validate(valid => {
        if (valid) {
          // this.$router.push('')
          this.activeStep++
          this.getCode()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .appeal-find{
    height: 100%;
    /*width: 100%;*/
    //background: $bg url('bg.png') center no-repeat;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    .mobile-test {
      font-size: 12px;
      .phone-code-box{
      ::v-deep .el-input{
        width: 60%;
      }
    }
      .test-tip {
        margin: 20px;
        height: 40px;
        line-height: 40px;
        background: #f9f1da;
        color: #202020;
        .svg {
          margin: 0 10px;
        }
      }
      .send.el-button {
        display: inline-block;
        width: 88px;
        height: 40px;
        /*line-height: 40px;*/
        background: #f2f2f2;
        color: #737d85;
        /*font-size: 12px;*/
        /*text-align: center;*/
        /*cursor: pointer;*/
        margin-left: 12px;
      }
    }
    .content {
      font-size: 12px;
      color: #313a46;
      padding-top: 72px;
      .steps {
        width: 100%;
        padding: 11px 0;
        margin-bottom: 60px;
      }
      .step-2 {
        font-size: 12px;
        color: #313a46;
        .svg {
          margin-right: 10px;
        }
      }
    }
  }
</style>

<style lang="less">
  .appeal-find {
    .content {
      .steps {
        .el-step__head.is-process {
          color: #5b8cff;
          border-color: #5b8cff;
        }
        .el-step__head.is-finish {
          color: #089e74;
          border-color: #089e74;
        }
        .el-step__icon {
          width: 32px;
          height: 32px;
          font-size: 12px;
        }
        .el-step__title.is-process {
          color: #5b8cff;
        }
        .el-step__title.is-finish {
          color: #089e74;
        }
        .el-step__title {
          font-size: 12px;
        }
        .el-step.is-horizontal .el-step__line {
          top: 15px;
          background: #d0d0d0;
        }
      }
      .el-input {
        width: 300px;
      }
    }
    .mobile-test.creat-dialog .el-dialog {
      width: 600px !important;
      min-width: 0;
    }
  }
</style>
