import request from '@/utils/requestAuth'
const auth = '/service-auth-provider/v2'
// 请求修改邮箱短信验证码
export function getEmailSms() {
  return request({
    url: auth + '/sms/alterEmail',
    method: 'post'
  })
}
// 请求修改手机号短信验证码
export function getPhoneSms() {
  return request({
    url: auth + '/sms/alterPhone',
    method: 'post'
  })
}
// 请求修改密码短信验证码
export function getPasswordSms() {
  return request({
    url: auth + '/sms/alterPwd',
    method: 'post'
  })
}

// 请求找回密码短信验证码
export function getFindPasswordSms(data) {
  return request({
    url: auth + '/sms/findPwd',
    method: 'post',
    data
  })
}
// 请求手机号登录短信验证码
export function getLoginSms(data) {
  return request({
    url: auth + '/sms/login',
    method: 'post',
    data
  })
}
// 请求注册短信验证码
export function getRegisterSms(data) {
  return request({
    url: auth + '/sms/register',
    method: 'post',
    data
  })
}
// 找回密码人工申诉短信验证码
export function getFindPwdSms(data) {
  return request({
    url: auth + '/sms/appeal',
    method: 'post',
    data
  })
}
